/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	var flexFallback = {
		init: function(){
			$(window).on('load resize orientationchange',function(){
				var items = $('.home-item:not(.half-height),.card.management');

				items.each(function(){
					$(this).height('auto');
					flexFallback.setHeight($(this));
				});
			});
		},

		setHeight: function(item){
			var itemMargin = parseInt(item.css('margin-bottom')),
				paddingTop = parseInt(item.css('padding-top')),
				paddingBottom = parseInt(item.css('padding-bottom')),
				parentHeight = item.parent().height(),
				height = parentHeight - itemMargin - paddingTop - paddingBottom;

			item.height(height);
		}
	},

	linkTable = {
		row: null,

		init: function(){
			var hashValue = location.hash.substr(1).split(';');
			linkTable.row = hashValue[1];

			if(hashValue[0]==='table') {
				linkTable.openAccordion();
			}
		},

		openAccordion: function(){
			if(!$('table thead tr[data-row='+linkTable.row+']').parents('.collapse').hasClass('show')) {
				$('table thead tr[data-row='+linkTable.row+']').parents('.accordion').find('.collapse.show').collapse('hide');
				$('table thead tr[data-row='+linkTable.row+']').parents('.collapse').collapse('show');
			}

			linkTable.showDetails();
		},

		showDetails: function(){
			if($('table thead tr[data-row='+linkTable.row+']').hasClass('detail') && !$('table thead tr[data-row='+linkTable.row+']').parents('table').hasClass('detailed')) {
				$('table thead tr[data-row='+linkTable.row+']').parents('table').siblings('.detail-toggle').click();
			}

			setTimeout(linkTable.scrollToRow,500);
		},

		scrollToRow: function(){
			var offsetTop = $('table thead tr[data-row='+linkTable.row+']').offset().top,
				scrollTo = offsetTop - ($(window).height() / 2);

			var body = $("html, body");
			body.stop().animate({scrollTop:scrollTo}, '500', 'linear', linkTable.highlightRow);
		},

		highlightRow: function(){
			$('table tr[data-row='+linkTable.row+']').addClass('hover');
		}
	},

	matchRowHeights = {
		init: function(){
			$(window).on('load shown.bs.collapse',function(){
				setTimeout(function(){
					matchRowHeights.setHead();
					$.fn.matchHeight._update();
				},500);
			});

			var timer;
			$(window).on('load resize orientationchange shown.bs.collapse',function(){
				clearTimeout(timer);
				timer = setTimeout(matchRowHeights.setData, 1000);
				// matchRowHeights.setData();
			});

			$(window).on('hide.bs.collapse',function(){
				matchRowHeights.unset();
			});

			$('.accordion a').not('.accordion a.collapsed').click(function(){
				$('#loader').modal('show');
			});

			// $.fn.matchHeight._beforeUpdate = function(event, groups) {
			// 	//$('#loader').modal('show');
			// };

			$.fn.matchHeight._afterUpdate = function(event, groups) {
				$('.accordion .collapse.show table').addClass('rendered');
				$('#loader').modal('hide');
			};
		},

		setHead: function(){
			// match row heights for th
			$('.accordion .collapse.show table thead tr th').each(function(){
				var dataAttr = $(this).parent().attr('data-row');
				$('.accordion .collapse.show table [data-row='+dataAttr+']>th:not(.print-title)>div').matchHeight({
					byRow: false,
					property: 'min-height'
				});
			});
		},

		setData: function(){
			// match row heights for td
			$('.accordion .collapse.show table thead tr td>div').each(function(){
				var minHeight = 34,
					paddingTop = parseInt($(this).css('padding-top')),
					paddingBottom = parseInt($(this).css('padding-bottom')),
					$tr = $(this).parent().parent(),
					cellHeight = $(this).outerHeight(),
					rowId = $tr.attr('data-row');

				if($tr.hasClass('no-padding-bottom')) {
					minHeight = 29;
				} else if($tr.prev().hasClass('total')) {
					minHeight = 54;
				}

				$('.accordion .collapse.show table tbody tr[data-row='+rowId+'] td>div').css({
					'min-height': (cellHeight>minHeight ? cellHeight : minHeight)
				});
			});
		},

		unset: function() {
			$('.accordion .collapsing table tr').matchHeight({ remove: true });
		}
	};

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				// hyphanator
				$('th,td').addClass('hyphenate');
				Hyphenator.run();

				flexFallback.init();
				matchRowHeights.init();

				// wow.js
				var wow = new WOW(
				{
					boxClass:     'wow',      // default
					animateClass: 'animated', // default
					offset:       0,          // default
					mobile:       true,       // default
					live:         true        // default
				});
				wow.init();

				$('#year-container .toggle').click(function(){
					$('#year-container').toggleClass('open');
				});

				var yearsWidth = 0;
				$('#year-container .scroll-container a').each(function(){
					yearsWidth += $(this).outerWidth(true);
				});

				$('#year-container .scroll-container > div').width(yearsWidth);

				// $('#year-container .scroll-container').scrollLeft(500);

				// $('table').each(function(){
				// // 	$('<div class="external-scroll_x"><div class="scroll-element_outer"><div class="scroll-element_size"></div><div class="scroll-element_track"></div><div class="scroll-bar"></div></div></div>').insertAfter($(this));

				// 	$(this).find('tbody').scrollbar({
				// 		"autoScrollSize": false
				// 	});
				// });

				$('.scroll-container').each(function(){
					$(this).scrollbar({
						"autoScrollSize": false,
						"scrollx": $(this).next('.external-scroll_x')
					});
				});

				var left = $('#year-container .scroll-container').scrollLeft(),
					scrollStep = 100,
					scrollLeft = ($(this).hasClass('left')) ? left - scrollStep : left + scrollStep;
				$('#year-container .scroll-container').scrollLeft(scrollLeft * 5 + 100);

				// search
				$('#main-header .meta .search, #search-container .close-x').click(function(){
					$('#download-container').removeClass('open');
					$('#main-header .meta .download').removeClass('current');
					$('#search-container').toggleClass('open');
					$('#main-header .meta .search').toggleClass('current');

					if($('#search-container').hasClass('open')) {
						$('#search-container input[type=search]').focus();
					}
				});

				// downloads
				$('#main-header .meta .download,#download-container .close-x').click(function(){
					$('#search-container').removeClass('open');
					$('#main-header .meta .search').removeClass('current');
					$('#download-container').toggleClass('open');
					$('#main-header .meta .download').toggleClass('current');
				});


				// toggle detail view for table
				$('.detail-toggle').click(function(){
					$(this).toggleClass('detailed');
					$(this).siblings('table').toggleClass('detailed');
				});

				// table row hover
				$('table tr').hover(function(){
					//in
					var row = $(this).attr('data-row');
					$('[data-row='+row+']').addClass('hover');
				},function(){
					//out
					var row = $(this).attr('data-row');
					$('[data-row='+row+']').removeClass('hover');
				});

				$(window).on('load hashchange',linkTable.init);

				// table scroll handles
				$('table').each(function(){
					$('<div class="table-scroll-handles hidden-xs-down"><div class="left"></div><div class="right"></div></div>').insertBefore(this);
				});

				$('.table-scroll-handles>div').on('click',function(){
					var scrollStep = 170,
						$tbody = $(this).parent().next('table').find('tbody'),
						currentLeft = $tbody.scrollLeft(),
						scrollTo;

					if($(this).hasClass('left')) {
						// left
						scrollTo = currentLeft - scrollStep;
					} else {
						// right
						scrollTo = currentLeft + scrollStep;
					}

					$tbody.animate({
						scrollLeft: scrollTo
					});
				});

				$('.table-scroll-handles').each(function(){
					var $table = $(this).next('table'),
						$handles = $(this);

					$(document).on('scroll',function(){
						var scrollTop = $(document).scrollTop(),
							tableTop = $table.offset().top,
							tableHeight = $table.height(),
							maxTop = tableHeight - 136,
							calcTop = 0;

						if(scrollTop>tableTop) {
							calcTop = scrollTop - tableTop;
						}

						calcTop = Math.min(calcTop,maxTop);

						$handles.css({
							top: calcTop
						});
					});
				});

				$(window).on('load resize orientationchange shown.bs.collapse',function(){
					$('.table-scroll-handles').each(function(){
						var $table = $(this).next('table'),
							$handles = $(this);

						if($table.find('tbody tr:first').width()<=$table.find('tbody').width()) {
							$handles.attr('hidden','hidden');
							$table.removeClass('scrollable');
							$table.removeClass('scroll-shadow');
						} else {
							$handles.removeAttr('hidden');
							$table.addClass('scrollable');
							$table.addClass('scroll-shadow');
						}
					});
				});

				// IE / Edge tables fix
				setTimeout(function() {
					$(window).trigger('resize');
				}, 500);

				$(window).on('shown.bs.collapse',function(){
					var $collapse = $('.collapse.show');

					if($collapse.parents('.accordion').length>0) {
						var offsetTop = $collapse.parents('.card').offset().top;

						$('html, body').animate({
							scrollTop:offsetTop
						},'slow');
					}
				});

				$('table').each(function(){
					$('<div class="scroll-element scroll-x"><div class="scroll-element_outer"><div class="scroll-element_size"></div><div class="scroll-element_track"></div><div class="scroll-bar" style="left: 0px;"></div></div></div>').insertAfter(this);

					var $table = $(this),
						$tbody = $table.find('tbody'),
						$tr = $tbody.find('tr:first'),
						$scrollElement = $table.next('.scroll-element'),
						$scrollbar = $scrollElement.find('.scroll-bar');

					$(window).on('load resize orientationchange shown.bs.collapse',function(){
						if($tr.width() > $tbody.width()) {
							$scrollElement.addClass('scroll-scrollx_visible');
						} else {
							$scrollElement.removeClass('scroll-scrollx_visible');
						}
					});

					$tbody.on('scroll resize',function(){
						var tbodyWidth = parseInt($tbody.width()),
							trWidth = parseInt($tr.width()),
							ratio = 1 / (trWidth - tbodyWidth) * 70,
							scrollLeft = parseInt($(this).scrollLeft()) * ratio,
							right = trWidth - tbodyWidth - scrollLeft;

						$scrollbar.css('left',scrollLeft+'px');

						if(right<=20) {
							$table.removeClass('scroll-shadow');
						} else {
							$table.addClass('scroll-shadow');
						}
					});
				});

				// Mobile Menu
				$('.nav.meta li.mobile-menu a,#mobile-menu-container .close-x').click(function(){
					$('#year-container').removeClass('open');
					$('body').toggleClass('modal-open');
					$('#mobile-menu-container').toggleClass('open');
				});

				$('#mobile-menu-container ul.nav > li > a').click(function(e){
					if($(this).siblings('ul').length>0) {
						e.preventDefault();
						$(this).parent().siblings('.current-menu-parent').removeClass('current-menu-parent');
						$(this).parent().addClass('current-menu-parent');
						return false;
					}
				});

				if($('.valign-h1').length>0) {
					var timer;

					$(window).on('load resize orientationchange',function(){
						var $container = $('.valign-h1');

						$container.find('h1').css({
							margin: '0px'
						});

						var containerHeight = $container.height(),
							titleHeight = $container.find('.title').outerHeight(true),
							h1Height = $container.find('h1').height(),
							margin = Math.max(30, (containerHeight - titleHeight - h1Height) / 2);

						clearTimeout(timer);
						timer = setTimeout(function(){
							$container.find('h1').css({
								margin: margin+'px 0px'
							});
						}, 100);

					});
				}
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$('#to-top').click(function(){
					var body = $("html, body");
					body.stop().animate({scrollTop:0}, '500', 'linear');
				});

				$('div.i>span').click(function(){
					$(this).siblings('div').fadeToggle();
				});

				$('div.i .close-x').click(function(){
					$(this).parent('div').fadeToggle();
				});

				// Prepare table title for print
				$('thead tr').each(function(){
					var row = $(this).attr('data-row'),
						$cell = $(this).children();

					$cell.clone().addClass('print-title').prependTo('tbody tr[data-row='+row+']');
				});

				$('.owl-carousel').owlCarousel({
					items: 1,
					loop: true,
					nav: true,
					autoplay: true,
          autoplayTimeout: 8000,
				});

				// Get internal var
				jQuery.ajax({
					type:"POST",
					url: ajaxurl,
					data: {
						action: "get_internal"
					},
					success:function(data){
						var internal = data==true ? 'true' : 'false';

						dataLayer.push({
							'internal': internal, // oder false
							'event': 'ajaxComplete'
						});
					},
					error: function(errorThrown){
						// alert(errorThrown);
					}
				});
			}
		},

		'home': {
			init: function(){
				$('.half-height').matchHeight({
					byRow: false
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
